import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import axios from "axios";
import "./ferienwohnung.scss";
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';

const Kontakt = () => {
    const { t, i18n } = useTranslation();

    const [ferienwohnungen, setFerienwohnungen] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "de" ? "" : i18n.language + "/";

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Ferienwohnungen
                const ferienwohnungenRes = await axios.get(`https://oberkroesser.com/wp-json/wp/v2/ferienwohnungen`);
                if (ferienwohnungenRes.data) {
                    setFerienwohnungen(ferienwohnungenRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [lang])

    usePageName(t("site.ferienwohnung") + " | " + t("site.pageName"));

    return (
        <div className="ferienwohnung">
            <div className="header">
                <div className="container">
                    <h1>{t("site.ferienwohnung")}</h1>
                    <p>{t("rooms.headerText")}</p>
                </div>
            </div>
            <div className="container">
                <div className="ferienwohnungen">
                    {isLoading ? <div className='loading' /> : ferienwohnungen.map(item => (
                        <Link to={`/${i18n.language}/ferienwohnung/${item.slug}`} key={item.id} className="item">
                            <div className="top">
                                <img
                                    className="image"
                                    src={item.acf.image}
                                    alt={item.title.rendered}
                                />
                                <div className="right">
                                    <span><OpenInFullOutlinedIcon /> {item.acf.size}</span>
                                    <span><PeopleOutlinedIcon /> {item.acf.personen}</span>
                                    <p>{item.acf.beschreibung}</p>
                                </div>
                            </div>
                            <h2>{item.title.rendered}</h2>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Kontakt;
