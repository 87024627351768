import React, { useEffect, useState } from "react";
import { usePageName } from "react-page-name";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./home.scss"

const Home = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.pageName"));

    const [header, setHeader] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow prev" onClick={onClick}>
                <ArrowBackOutlinedIcon />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
            <div className="arrow next" onClick={onClick}>
                <ArrowForwardOutlinedIcon />
            </div>
        );
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                // Fetch für Header
                const headerRes = await axios.get(`https://oberkroesser.com/wp-json/wp/v2/header`);
                if (headerRes.data && headerRes.data.length > 0) {
                    setHeader(headerRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        };

        fetchData();
    }, [lang]);

    return (
        <div className="home">
            <div className="header-start">
                <div className="container">
                    <h1>{t("home.headerText")}</h1>
                </div>
                <div className="slideshow">
                    {isLoading ? (
                        <div className="laden"></div>
                    ) : (
                        <>
                            <Slider {...sliderSettings}>
                                {header.map((item, index) => (
                                    <div key={index}>
                                        {item.acf.image.endsWith(".mp4") ? (
                                            <video muted loop autoPlay src={item.acf.image} />
                                        ) : (
                                            <img src={item.acf.image} alt="Hintergrundbild" />
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        </>
                    )}
                </div>
            </div>
            <div className="ferienwohnung">
                <div className="left">
                    <p>{t("home.textStart")}</p>
                    <img src="https://oberkroesser.com/wp-content/uploads/2024/08/20240709-MTN09771-scaled.jpg" alt="Urlaub" />
                </div>
                <img className="imageLeft" src="https://oberkroesser.com/wp-content/uploads/2024/08/20240709-MTN09780-scaled.jpg" alt="Ferienwohnung" />
                <div className="right">
                    <h2>{t("home.stilvollUndGemütlich")}</h2>
                    <Link to={`/${i18n.language}/ferienwohnung`}>{t("home.deinUrlaubszuhause")}</Link>
                </div>
            </div>
            <div className="vacation">
                <div className="left">
                    <h2>{t("home.entdeckenH2")}</h2>
                    <p>{t("home.entdeckenText")}</p>
                    <img src="https://oberkroesser.com/wp-content/uploads/2024/08/20240709-MTN09845-scaled.jpg" alt="Urlaub" />
                </div>
                <img className="imageLeft" src="https://oberkroesser.com/wp-content/uploads/2024/08/20240709-MTN09832-scaled.jpg" alt="Ferienwohnung" />
                <div className="right">
                    <h2>{t("home.urlaubAufDemBauernhof")}</h2>
                    <Link to={`/${i18n.language}/entdecken`}>{t("site.entdecken")}</Link>
                </div>
            </div>
        </div>
    )
};

export default Home