import React from 'react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { usePageName } from "react-page-name";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import "./entdecken.scss"
import moment from 'moment';
import 'moment/locale/de'
moment.locale('de')

const Entdecken = () => {
    const { t, i18n } = useTranslation();

    usePageName(t("site.entdecken") + " | " + t("site.pageName"));

    const [erleben, setErleben] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const lang = i18n.language === "it" ? "it/" : "";

    useEffect(() => {
        const fetchAllRef = async () => {
            try {
                // Fetch für Aussstattung
                const erlebenRes = await axios.get(`https://oberkroesser.com/wp-json/wp/v2/erleben`);
                if (erlebenRes.data) {
                    setErleben(erlebenRes.data);
                }

                setLoading(false);
            } catch (err) {
                console.log(err);
            }
        }
        fetchAllRef()
    }, [lang])

    return (
        <div className='entdecken'>
            <div className='header'>
                <div className="container">
                    <h1>{t("site.entdecken")}</h1>
                </div>
            </div>
            <div className="container">
                <div className="ferienwohnungen">
                    {isLoading ? <div className='loading' /> : erleben.map(item => (
                        <Link to={`/${i18n.language}/ferienwohnung/${item.slug}`} key={item.id} className="item">
                            <div className="top">
                                <img
                                    className="image"
                                    src={item.acf.image}
                                    alt={item.title.rendered}
                                />
                                <div className="right">
                                    <p>{item.acf.beschreibung}</p>
                                </div>
                            </div>
                            <h2>{item.title.rendered}</h2>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Entdecken
